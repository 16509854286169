import React from "react";
import { css } from "@emotion/react";
import { BarLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const DownloadPopup = ({ hideDownloadPopup, loading }) => {
  return (
    <div className="download-popup-overlay">
      <div className="download-popup-content">
        <h2 style={{ color: "black" }}>Please Wait...</h2>
        <div className="loader">
          <BarLoader color={"#e7004d"} css={override} />
        </div>
        <h3 style={{ color: "black", marginTop:"20px" }}>Comments Downloading!</h3>
        <button
          type="button"
          onClick={() => hideDownloadPopup()}
          className="close-button"
          style={{ pointerEvents: "auto" }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default DownloadPopup;
