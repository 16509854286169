import React, { useState, useReducer, useEffect } from "react";

import "./App.scss";
import { FaPencilAlt } from "react-icons/fa";
import { RxFramerLogo } from "react-icons/rx";
import Card from "./Components/Card";
import Topbar from "./Components/Topbar";
import { css } from "@emotion/react";
import { PropagateLoader } from "react-spinners";
import Footer from "./Components/Footer";
import DownloadPopup from "./Components/DownloadPopup";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Loader = () => {
  return (
    <div className="loader">
      <PropagateLoader color={"#e7004d"} css={override} />
    </div>
  );
};

function App() {
  //const HOST = 'http://localhost:5001'
  const HOST = "http://143.198.58.92:5001";

  const [videoURL, setVideoURL] = useState("");
  const [videoData, setVideoData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [getVideoSubsError, setGetVideoSubsError] = useState("");
  console.log("🚀 ~ App ~ getVideoSubsError:", getVideoSubsError);

  const handleFetchVideoData = async () => {
    setLoading(true);
    setError("");
    setGetVideoSubsError("");
    try {
      const response = await fetch(
        `https://instagramserver.tokbackup.com/get_single_media_info_from_url?media_id=${encodeURIComponent(
          videoURL
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "cross-origin-resource-policy": "cross-origin",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch video data");
      }
      const data = await response.json();
      console.log("🚀 ~ data>>>>>>>>>>>>..:", data);
      console.log("🚀 ~ data.error>>>>>>>>>>>>..:", data?.error);
      if (data?.error) {
        setError(data?.error);
      } else {
        setVideoData(data);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const CheckIfTiktokLinkIsValid = () => {
    if (!videoURL) return false;
    try {
      var id = videoURL?.match(/https?:\/\/(www\.)?instagram\.com\//);

      if (id) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e.message);
      return false;
    }
  };

  return (
    <div className="App ">
      <Topbar />
      <div className=" bg-gradient">
        <div style={{ paddingTop: "75px" }}>
          <img
            src="../fav.png"
            alt="logo"
            style={{
              fontSize: "75px",
              color: "#ccdae7",
              margin: "auto",
              width: "150px",
            }}
          />
        </div>
        <h1
          style={{
            color: "white",
            width: "90%",
            maxWidth: "720px",
            margin: "auto",
            marginTop: "30px",
            marginBottom: "-20px",
            fontFamily: "DM Sans",
          }}
          className="sm:text-5xl text-4xl font-bold  leading-normal "
        >
          <i>
            {" "}
            IG{" "}
            <span
              className="text-gradient__indigo-red px-0"
              style={{ fontWeight: "1000" }}
            >
              {" "}
              Transcript{" "}
            </span>{" "}
            Generator{" "}
          </i>
        </h1>

        <h5
          className="sm:text-md text-base font-medium leading-normal mt-7"
          style={{ color: "white" }}
        >
          <i>Turn speech into text for any Instagram video</i>
        </h5>

        <div
          className="flex justify-center mt-20"
          style={{ marginBottom: "-15px" }}
        >
          <div
            className="block p-6 rounded-lg  "
            style={{ width: "90%", maxWidth: "720px" }}
          >
            <div className="mb-3 flex items-center">
              <div class="p-1 w-full rounded-full bg-gradient-to-r from-[#152ce2] via-[#a205c3] to-[#e7004d] md:mr-2">
                <div class="flex flex-col sm:flex-row items-center font-semibold bg-gray-800 rounded-full w-full">
                  <div class="flex w-full items-center">
                    <input
                      type="text"
                      className="  block w-full px-10 py-4 text-base font-medium 
              text-gray-900 shadow bg-white bg-clip-padding  
              rounded-3xl transition ease-in-out m-0  
               "
                      placeholder="Insert a Instagram link..."
                      value={videoURL}
                      style={{
                        border: "none",
                        outline: "none",
                        borderRadius: "50px",
                      }}
                      onChange={(e) => setVideoURL(e.target.value)}
                    />{" "}
                  </div>
                </div>
              </div>

              <button
                type="button"
                className=" 
            inline-block px-6 py-2.5 bg-blue-600 text-white  
            font-medium text-xs leading-tight uppercase rounded-3xl 
            shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 
            focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 
            active:shadow-lg transition duration-150 ease-in-out  -ml-32
            
             bg-gradient-to-r from-[#152ce2] via-[#a205c3] to-[#e7004d] whitespace-nowrap
            "
                style={{
                  opacity: loading ? 0.5 : 1,
                  pointerEvents: loading ? "none" : "auto",
                  marginLeft: "-110px",
                  backgroundColor: "rgb(221, 175, 36)",
                }}
                onClick={() => {
                  if (!CheckIfTiktokLinkIsValid()) {
                    // setSuccessMessage("");
                    setGetVideoSubsError(
                      "Please enter a valid instagram video url"
                    );

                    return;
                  }
                  console.log("valid instagram url !");
                  handleFetchVideoData();
                }}
              >
                START
              </button>
            </div>

            {loading && (
              <>
                <h2
                  style={{
                    marginTop: "100px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {/* Loading Data... */}
                  <Loader />
                </h2>
                <h3
                  className="animate-pulse"
                  style={{
                    color: "white",
                    marginTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Getting Video Info....
                </h3>
              </>
            )}

            {(!loading && error) ||
              (getVideoSubsError && (
                <div
                  className="text-white text-xs mt-4 bg-red-500 rounded-2xl px-4 py-1"
                  style={{ width: "fit-content", margin: "auto" }}
                >
                  {error || getVideoSubsError}
                </div>
              ))}
          </div>
        </div>
      </div>

      <div
        className="flex justify-center my-12 mb-2"
        style={{ marginTop: "-180px" }}
      ></div>

      {!loading && videoData && !getVideoSubsError ? (
        <Card
          data={videoData}
          loading={loading}
          videoURL={videoURL}
          // successMessage={successMessage}
          // getVideoSubsError={error}
        />
      ) : (
        ""
      )}

      <Footer />
    </div>
  );
}

export default App;
