import React from "react";
import { css } from "@emotion/react";
import { BarLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const LoadingPopup = ({ hideDownloadPopup, loading, errorMsg }) => {
  return (
    <div className="download-popup-overlay" style={{ margin: "auto" }}>
      {!errorMsg ? (
        <div className="download-popup-content">
          <h2 style={{ color: "black" }}>Fetching video info...</h2>
          <div className="loader">
            <BarLoader color={"#e7004d"} css={override} />
          </div>
          {/* <h3 style={{ color: "black", marginTop:"20px" }}>Comments Loading!</h3> */}
          <h3
            style={{
              color: "black",
              margin: "30px 0",
              display: "flex",
              justifyContent: "center",
              margin:"auto"
            }}
          >
            This can take anywhere from 10Secs-30Secs depending on how many CC are on this video.
            Please leave this  open and check back in a bit!
          </h3>
          {/* <button
            type="button"
            onClick={() => hideDownloadPopup()}
            className="close-button"
            style={{ pointerEvents: "auto" }}
          >
            Close
          </button> */}
        </div>
      ) : (
        <div className="download-popup-content">
          <h2 style={{ color: "black" }}>Fetch Data Failed!</h2>
          <div className="loader">
            {/* <BarLoader color={"#e7004d"} css={override} /> */}
          </div>
          <h3
            style={{
              color: "red",
              margin: "30px 0",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {errorMsg}
          </h3>
        </div>
      )}
    </div>
  );
};

export default LoadingPopup;
